import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { isUndefined, Solution, SOLUTIONS_NL } from '@energiebespaarders/constants';
import useForm, {
  isInvalidEmail,
  isInvalidPhoneNumber,
  isMissing,
} from '@energiebespaarders/hooks/useForm';
import {
  Accordion,
  Box,
  Button,
  Flex,
  Input,
  Select,
  Separator,
  TextLink,
} from '@energiebespaarders/symbols';
import { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import { StyledOverlay } from '@energiebespaarders/symbols/components/SpinnerOverlay';
import { Medium, Red, Small } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { Cancel, Check } from '@energiebespaarders/symbols/icons/solid';
import _ from 'lodash';
import { useRouter } from 'next/router';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import config from '~/config';
import { useActiveHouseId } from '~/hooks/useActiveHouseId';
import useAppointmentDropdown from '~/hooks/useAppointmentDropdown';
import { useClearSession } from '~/hooks/useClearSession';
import { useDebouncedValue } from '~/hooks/useDebounce';
import { dutyPackagesWithoutIntake } from '~/hooks/useDutyPackage';
import useGAEvent from '~/hooks/useGAEvent';
import useIsAuthenticated from '~/hooks/useIsAuthenticated';
import { useLeadEmail } from '~/hooks/useLeadEmail';
import { useMeOptional } from '~/hooks/useMe';
import { LOGOUT_CUSTOMER, UPDATE_CUSTOMER } from '~/queries/customer';
import { CHECK_EMAIL_EXISTS } from '~/queries/forms';
import { GET_ME } from '~/queries/initialize';
import {
  REQUEST_DUTY_PACKAGE_AS_CUSTOMER,
  REQUEST_DUTY_PACKAGE_AS_LEAD,
} from '~/queries/orderItem';
import { GET_HYPOTHEEK_VISIE_OFFICES, GET_VELDSINK_OFFICES } from '~/queries/samen';
import { checkEmailExists, checkEmailExistsVariables } from '~/types/generated/checkEmailExists';
import { getHypotheekVisieOffices } from '~/types/generated/getHypotheekVisieOffices';
import { getVeldsinkOffices } from '~/types/generated/getVeldsinkOffices';
import { logoutCustomer } from '~/types/generated/logoutCustomer';
import { me as Me } from '~/types/generated/me';
import {
  requestDutyPackageAsCustomer,
  requestDutyPackageAsCustomerVariables,
} from '~/types/generated/requestDutyPackageAsCustomer';
import {
  requestDutyPackageAsLead,
  requestDutyPackageAsLead_requestDutyPackageAsLead,
  requestDutyPackageAsLeadVariables,
} from '~/types/generated/requestDutyPackageAsLead';
import {
  storeVeldsinkRequestInfo,
  storeVeldsinkRequestInfoVariables,
} from '~/types/generated/storeVeldsinkRequestInfo';
import { updateCustomer, updateCustomerVariables } from '~/types/generated/updateCustomer';
import { AddressInput, DutyPackageName } from '~/types/graphql-global-types';
import {
  createMarketingConsent,
  createPrivacyConsent,
  createTAndCConsent,
  getPartnerConsent,
} from '~/utils/createConsent';
import delimit from '~/utils/delimit';
import { CustomDutyPackageProps, DutyPackageWithPrice } from '~/utils/dutyPackages';
import { formatPhoneE164 } from '~/utils/formatPhoneNumber';
import { inIFrame } from '~/utils/iFrameUtils';
import { encodeParams, SelfServiceIntakeParams } from '~/utils/paramUtils';
import { getUtcAppointmentFromDateAndTimeSlot } from 'src/envPartner/PartnerRequest/utils';
import { Gender } from 'src/envPartner/PartnerRequest/PartnerRequestForm';
import { getActiveHouseId, identifyMe } from '../_app/initializeSession';
import { FormAddress } from '../AddressFields';
import DelayedServiceWarning from '../DelayedServiceWarning';
import HybridLink from '../HybridLink';
import AddressInitForm from './AddressInitForm';
import { MarketingConsentCheckbox, TermsConsentCheckbox } from './ConsentCheckbox';
import GenderInput from './GenderInput';
import LeadFilterForm, { LeadFilterQuestion } from './LeadFilterForm';
import SolutionChecklist, { SolutionOption } from './SolutionChecklist';
import {
  getAppointmentDateOptions,
  getLocalJourneyData,
  timeSlotOptions,
  translateRequestError,
} from './utils';
import VoucherInput from './VoucherInput';

const STORE_VELDSINK_REQUEST_INFO = gql`
  mutation storeVeldsinkRequestInfo($houseId: ID!, $veldsinkOffice: String!) {
    storeVeldsinkRequestInfo(houseId: $houseId, veldsinkOffice: $veldsinkOffice)
  }
`;

export const buildInterestsNote = (packageKey: string, solutions: SolutionOption[]) => {
  if (solutions.length === 0) return;

  // NEW SALES FLOW - v0.1: return our solution enum values as csv
  if (packageKey === DutyPackageName.quotesOnly) {
    return solutions.join(',');
  }

  if (solutions.includes('all')) {
    return 'Klant heeft aangegeven nog geen voorkeur voor bepaalde oplossingen te hebben.';
  }
  const interestsNL = solutions.map(solution =>
    solution === 'all' ? 'Alle' : SOLUTIONS_NL[solution],
  );
  return `Klant heeft interesse aangegeven voor
    ${interestsNL.slice(0, interestsNL.length - 1).join(', ')} en ${interestsNL.slice(-1)}`;
};
export interface RequestPackageFormValues {
  gender: Gender;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  appointmentDate: string;
  timeSlot: number;
  consentTerms: boolean;
  consentMarketing: boolean;
  consentPartnerSharing: boolean;
  voucherCode: string;
  partnerOfficeId: string;
  selectedHouse: string;
  solutionInterests: SolutionOption[];
}

export interface RequestPackageFormProps {
  dutyPackage: DutyPackageWithPrice;
  submitText?: string;
  initialValues?: Partial<RequestPackageFormValues>;
  initialAddress?: Partial<AddressInput>;
  affiliate?: string;
  redirectParams?: Record<string, string>;
  customPackageProps?: CustomDutyPackageProps;
  overrideCollapse?: boolean;
  hideIntro?: boolean;
  showSolutionChecklist?: boolean;
  validateAddress?: (address: FormAddress) => boolean;
}

const RequestPackageForm: React.FC<RequestPackageFormProps> = ({
  dutyPackage,
  submitText,
  initialValues,
  initialAddress,
  affiliate,
  redirectParams,
  customPackageProps,
  overrideCollapse,
  hideIntro,
  showSolutionChecklist,
  validateAddress,
}) => {
  const { me, setMe } = useMeOptional();
  const { activeHouseId, setActiveHouseId } = useActiveHouseId();
  const isAuthenticated = useIsAuthenticated();
  const { leadEmail } = useLeadEmail();
  const isMobile = useIsMobile();
  const router = useRouter();
  const sendGAEvent = useGAEvent();
  const [cookies] = useCookies(['promoCoupon']);

  const [selectedHouseId, setSelectedHouseId] = useState(activeHouseId);
  const [emailExistsError, setEmailExistsError] = useState('');
  const [voucherCodeValueMsg, setVoucherCodeValueMsg] = useState('');
  const [voucherCodeInvalidError, setVoucherCodeInvalidError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [leadBlockMessage, setLeadBlockMessage] = useState('default');
  const [discountAmount, setDiscountAmount] = useState<number | undefined>();
  const showAppointmentDropdown = useAppointmentDropdown();

  const [getMe] = useLazyQuery<Me>(GET_ME, {
    fetchPolicy: 'network-only',
    onCompleted: ({ me: newMe }) => {
      if (newMe.__typename === 'Customer' && newMe.id !== me?.id) {
        const houseId = getActiveHouseId(newMe);
        setMe(newMe);
        setActiveHouseId(houseId);
        identifyMe(newMe, houseId);

        void sendGAEvent('login', {
          method: 'request_advice',
          user_id: newMe.id,
          user_type: newMe.__typename,
          email: newMe.email,
          name: `${newMe.firstName} ${newMe.lastName}`,
          phone: formatPhoneE164(newMe.phone),
          intercom_hash: newMe.intercomHash,
        });
      }
    },
  });

  const appointmentDateOptions = useMemo(
    () => getAppointmentDateOptions(showAppointmentDropdown),
    [showAppointmentDropdown],
  );

  const packageProps = useMemo(
    () => ({ ...dutyPackage, ...customPackageProps }),
    [customPackageProps, dutyPackage],
  );

  const handleSubmitError = useCallback((err: Error) => {
    const msg = translateRequestError(err);
    if (msg) {
      setSubmitError(msg);
    } else {
      console.error(err);
      setSubmitError('Er is iets misgegaan');
    }
  }, []);

  const { clearSession } = useClearSession();
  const [logout] = useMutation<logoutCustomer>(LOGOUT_CUSTOMER, {
    onCompleted: async data => data.logoutCustomer && clearSession(router.asPath),
  });

  const trackRequest = useCallback(
    async (solutionInterests?: SolutionOption[], voucherCode?: string): Promise<void> => {
      const value = packageProps.price!;
      if (dutyPackage.key === DutyPackageName.quotesOnly) {
        void sendGAEvent('request_quotes', {
          house_id: activeHouseId,
          order_item: dutyPackage.key,
          solutions: solutionInterests,
        });
      } else {
        void sendGAEvent('request_advice', {
          house_id: activeHouseId,
          order_item: dutyPackage.key,
          currency: 'EUR',
          value,
          tax: _.round(value - value / 1.21, 2),
          coupon: discountAmount ? voucherCode : undefined,
          discount: discountAmount ? _.round(discountAmount, 2) : undefined,
        });
      }
    },
    [packageProps.price, sendGAEvent, activeHouseId, dutyPackage, discountAmount],
  );

  const hasDecimals = useMemo(() => packageProps.price! % 1 !== 0, [packageProps.price]);

  const { data: veldsinkData, loading: veldsinkOfficesLoading } = useQuery<getVeldsinkOffices>(
    GET_VELDSINK_OFFICES,
    { skip: packageProps.key !== DutyPackageName.veldsinkComplete },
  );
  const { data: hypotheekvisieData, loading: hvOfficesLoading } =
    useQuery<getHypotheekVisieOffices>(GET_HYPOTHEEK_VISIE_OFFICES, {
      skip: packageProps.key !== 'hypotheekVisieComplete',
    });

  const officeOptions: DropdownOption<string>[] = useMemo(() => {
    const cleanName = (name: string) => {
      const stripped = name.replace('Veldsink -', '').replace('Veldsink Advies - ', '');
      if (stripped.includes(',')) return stripped.split(',')[1];
      return stripped;
    };
    const offices = (
      veldsinkData?.veldsinkOffices ||
      hypotheekvisieData?.hypotheekVisieOffices ||
      []
    )
      .filter(office => !office.name.includes('DEB'))
      .map(office => ({
        label: cleanName(office.name),
        value: office.id,
      }));
    const initOptions = [
      { label: 'Kies een kantoor', value: '', disabled: true },
      { label: 'Weet ik niet / N.v.t.', value: 'unknown' },
    ];
    return initOptions
      .concat(offices.sort((a, b) => a.label.localeCompare(b.label)))
      .concat([{ label: 'Staat er niet tussen', value: 'missing' }]);
  }, [veldsinkData, hypotheekvisieData]);

  const [storeVeldsinkOfficeInfo] = useMutation<
    storeVeldsinkRequestInfo,
    storeVeldsinkRequestInfoVariables
  >(STORE_VELDSINK_REQUEST_INFO);

  const [requestDutyPackageAsLeadMutation, { loading: leadRequestLoading }] = useMutation<
    requestDutyPackageAsLead,
    requestDutyPackageAsLeadVariables
  >(REQUEST_DUTY_PACKAGE_AS_LEAD, {
    onError: handleSubmitError,
    onCompleted: async data => {
      if (data.requestDutyPackageAsLead?.orderId) {
        try {
          await getMe();
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          void router.push(createRedirectUrl(data.requestDutyPackageAsLead));
        } catch (error) {
          console.error(error);
        }
      }
    },
  });
  const [requestDutyPackageAsCustomerMutation, { loading: customerRequestLoading }] = useMutation<
    requestDutyPackageAsCustomer,
    requestDutyPackageAsCustomerVariables
  >(REQUEST_DUTY_PACKAGE_AS_CUSTOMER, {
    onError: handleSubmitError,
    onCompleted: async data => {
      if (data.requestDutyPackageAsCustomer?.orderId) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          void router.push(createRedirectUrl(data.requestDutyPackageAsCustomer));
        } catch (error) {
          console.error(error);
        }
      }
    },
  });

  const [updateCustomerMutation, { loading: updatingCustomer }] = useMutation<
    updateCustomer,
    updateCustomerVariables
  >(UPDATE_CUSTOMER);

  const loading = leadRequestLoading || customerRequestLoading || updatingCustomer;

  const handleRequestDutyPackageAsLead = useCallback(
    async (values: RequestPackageFormValues) => {
      setSubmitError('');
      const {
        gender,
        firstName,
        lastName,
        email,
        phone,
        consentTerms,
        consentMarketing,
        consentPartnerSharing,
        timeSlot,
        voucherCode,
        partnerOfficeId,
        solutionInterests,
      } = values;
      const termsConsent = createTAndCConsent('dutyPackageRequest');
      const privacyConsent = createPrivacyConsent('dutyPackageRequest');
      const partnerConsent = consentPartnerSharing && getPartnerConsent(dutyPackage.key);
      const mktConsent = createMarketingConsent('dutyPackageRequest');
      const journeyData = getLocalJourneyData();
      if (!dutyPackage) return window.alert('Er is geen pakket gekozen');
      const appointmentDate =
        values.appointmentDate === 'neutral'
          ? appointmentDateOptions[1].value
          : values.appointmentDate;
      const appointment = getUtcAppointmentFromDateAndTimeSlot(appointmentDate, timeSlot);
      try {
        await requestDutyPackageAsLeadMutation({
          variables: {
            houseId: selectedHouseId,
            request: {
              leadId: me!.id,
              dutyPackage: dutyPackage.key,
              gender,
              firstName,
              lastName,
              email,
              phone,
              consent: [
                ...(consentTerms ? [termsConsent, privacyConsent] : []),
                ...(partnerConsent ? [partnerConsent] : []),
                ...(consentMarketing ? [mktConsent] : []),
              ],
              appointment,
              voucherCode,
            },
            partnerOfficeId: partnerOfficeId === 'unknown' ? '' : partnerOfficeId,
            journeyData,
            affiliate,
            notes: buildInterestsNote(dutyPackage.key, solutionInterests),
          },
        }).then(() => trackRequest(solutionInterests, voucherCode));
      } catch (e) {
        handleSubmitError(e);
      }
    },
    [
      affiliate,
      appointmentDateOptions,
      dutyPackage,
      handleSubmitError,
      me,
      requestDutyPackageAsLeadMutation,
      selectedHouseId,
      trackRequest,
    ],
  );

  const meCustomer = me?.__typename === 'Customer' ? me : undefined;
  /** Customers that start the new funnel (jun 2024) only have a firstName and email. This form requires gender, lastName and phone too. */
  const isIncompleteCustomer =
    (me?.__typename === 'Customer' && (!me.lastName || !me.gender)) || me?.__typename === 'Lead';

  const handleRequestDutyPackageAsCustomer = useCallback(
    async (values: RequestPackageFormValues) => {
      setSubmitError('');
      const {
        gender,
        firstName,
        lastName,
        email,
        phone,
        timeSlot,
        voucherCode,
        solutionInterests,
      } = values;
      const journeyData = getLocalJourneyData();
      if (!dutyPackage) return window.alert('Er is geen pakket gekozen');
      const appointmentDate =
        values.appointmentDate === 'neutral'
          ? appointmentDateOptions[1].value
          : values.appointmentDate;
      const appointment = getUtcAppointmentFromDateAndTimeSlot(appointmentDate, timeSlot);
      try {
        if (isIncompleteCustomer) {
          await updateCustomerMutation({
            variables: {
              id: me!.id,
              customer: { gender, firstName, lastName, email },
            },
          });
        }
        await requestDutyPackageAsCustomerMutation({
          variables: {
            houseId: selectedHouseId,
            request: {
              customerId: me!.id,
              dutyPackage: dutyPackage.key,
              phone,
              appointment,
              voucherCode,
            },
            journeyData,
            notes: buildInterestsNote(dutyPackage.key, solutionInterests),
          },
        }).then(() => trackRequest(solutionInterests, voucherCode));
      } catch (e) {
        handleSubmitError(e);
      }
    },
    [
      appointmentDateOptions,
      dutyPackage,
      handleSubmitError,
      isIncompleteCustomer,
      me,
      requestDutyPackageAsCustomerMutation,
      selectedHouseId,
      trackRequest,
      updateCustomerMutation,
    ],
  );

  const { formState, handleChange, submitForm, errors, submissionAttempted, triggerValidation } =
    useForm<RequestPackageFormValues>({
      initialValues: {
        gender: isIncompleteCustomer ? '' : meCustomer?.gender || '',
        firstName: meCustomer?.firstName || '',
        lastName: meCustomer?.lastName || '',
        email: meCustomer?.email || leadEmail || '',
        phone: meCustomer?.phone || '',
        appointmentDate: appointmentDateOptions[0].value,
        timeSlot: timeSlotOptions[0].value,
        voucherCode: cookies.promoCoupon?.code || '',
        consentTerms: false,
        consentMarketing: false,
        consentPartnerSharing: false,
        partnerOfficeId: '',
        selectedHouse: '',
        solutionInterests: [],
        ...initialValues,
      },
      handleSubmit: values => {
        if (leadBlockMessage) return;
        if (dutyPackage.key === DutyPackageName.veldsinkComplete) {
          void storeVeldsinkOfficeInfo({
            variables: {
              houseId: selectedHouseId,
              veldsinkOffice: String(
                officeOptions.find(office => office.value === values.partnerOfficeId)?.label,
              ),
            },
          });
        }

        void (isAuthenticated.asCustomer
          ? handleRequestDutyPackageAsCustomer(values)
          : handleRequestDutyPackageAsLead(values));
      },
      validate: (values, errors) => {
        if (!selectedHouseId) {
          errors.selectedHouse =
            'Haal eerst de woninggegevens op met de knop "Woninggegevens ophalen"';
        }
        if (isMissing(values.gender)) errors.gender = 'Ontbreekt';
        if (isMissing(values.firstName)) errors.firstName = 'Ontbreekt';
        if (isMissing(values.lastName)) errors.lastName = 'Ontbreekt';
        if (isMissing(values.email)) errors.email = 'Ontbreekt';
        if (isInvalidEmail(values.email)) errors.email = 'Ongeldig e-mailadres';
        if (!isAuthenticated.asCustomer && emailExistsError) errors.email = emailExistsError;
        if (voucherCodeInvalidError) errors.voucherCode = voucherCodeInvalidError;
        if (values.consentTerms === false) errors.consentTerms = 'Benodigd';
        if (packageProps.key === 'ingComplete' && values.consentPartnerSharing === false) {
          errors.consentPartnerSharing = 'Benodigd';
        }
        if (!meCustomer?.phone && isMissing(values.phone)) errors.phone = 'Ontbreekt';
        if (
          isInvalidPhoneNumber(values.phone) ||
          values.phone === '0612345678' ||
          values.phone === '0123456789'
        ) {
          errors.phone = 'Ongeldig nummer';
        }
        if (!dutyPackage) errors.general = 'Er is geen dienstenpakket geselecteerd';
        if (
          packageProps.key === DutyPackageName.veldsinkComplete &&
          isMissing(values.partnerOfficeId)
        ) {
          errors.partnerOfficeId = 'Ontbreekt';
        }
        if (
          packageProps.key === DutyPackageName.quotesOnly &&
          values.solutionInterests.length === 0
        ) {
          errors.solutionInterests = 'Kies er ten minste 1';
        }
        return errors;
      },
    });

  useEffect(() => {
    // Due to optional me for static prerendering, it doesn't always get the meCustomer values in the initialValues
    if (meCustomer?.firstName && formState.firstName.value === '') {
      handleChange({ firstName: meCustomer.firstName });
    }
    if (meCustomer?.email && formState.email.value === '') {
      handleChange({ email: meCustomer.email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meCustomer]);

  const createRedirectUrl = useCallback(
    (data: requestDutyPackageAsLead_requestDutyPackageAsLead) => {
      const isEnergyLabelRequest = dutyPackage?.key.includes('energyLabelHouseType');

      const params: SelfServiceIntakeParams = {
        order: data.orderId,
        constructionYear: data.constructionYear || undefined,
        houseType: data.type || undefined,
        firstName: formState.firstName.value,
        lastName: formState.lastName.value,
        email: formState.email.value,
        requestType: isEnergyLabelRequest ? 'energyLabelRequest' : undefined,
      };

      if (inIFrame()) {
        const stringifiedParams = new URLSearchParams({ ...redirectParams }).toString();
        return `/iframe/bedankt?${stringifiedParams}`;
      }

      if (isEnergyLabelRequest) {
        return `/bedankt/${encodeParams(params)}`;
      }

      if (dutyPackagesWithoutIntake.includes(dutyPackage.key as DutyPackageName))
        return '/intake/inplannen';

      // In other (normal cases): Let customer plan their own house visit
      return '/intake';
    },
    [
      dutyPackage?.key,
      formState.email.value,
      formState.firstName.value,
      formState.lastName.value,
      redirectParams,
    ],
  );

  // E-mail validation
  // ------------------------------------------------------------
  const debouncedEmail = useDebouncedValue(formState.email.value, 200);

  const [checkEmailExistsMutation, { data: checkEmailData, loading: checkingEmail }] = useLazyQuery<
    checkEmailExists,
    checkEmailExistsVariables
  >(CHECK_EMAIL_EXISTS);

  useEffect(() => {
    if (debouncedEmail && !isInvalidEmail(debouncedEmail) && !checkingEmail) {
      void checkEmailExistsMutation({
        variables: { email: debouncedEmail },
      });
    }
  }, [debouncedEmail, checkingEmail, checkEmailExistsMutation]);

  useEffect(() => {
    if (checkEmailData?.checkEmailExists?.emailExists) {
      setEmailExistsError('Dit e-mailadres is al in gebruik');
    } else {
      setEmailExistsError('');
    }
  }, [checkEmailData]);

  // Form change handlers
  // ------------------------------------------------------------

  const handleChangeGender = useCallback(
    (value: Gender) => handleChange({ gender: value }),
    [handleChange],
  );
  const handleChangeFirstName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => handleChange({ firstName: e.target.value }),
    [handleChange],
  );
  const handleChangeLastName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => handleChange({ lastName: e.target.value }),
    [handleChange],
  );
  const handleChangeEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => handleChange({ email: e.target.value }),
    [handleChange],
  );
  const handleChangePhone = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => handleChange({ phone: e.target.value }),
    [handleChange],
  );
  const handleChangeAppointmentDate = useCallback(
    (e: DropdownOption<string>) => handleChange({ appointmentDate: e.value }),
    [handleChange],
  );
  const handleChangeTimeSlot = useCallback(
    (e: DropdownOption<number>) => handleChange({ timeSlot: e.value }),
    [handleChange],
  );
  const handleChangeVeldsinkOffice = useCallback(
    (e: DropdownOption<string>) => handleChange({ partnerOfficeId: e.value }),
    [handleChange],
  );
  const handleChangeTermsConsent = useCallback(
    (value: boolean) => handleChange({ consentTerms: value }),
    [handleChange],
  );
  const handleChangeMarketingConsent = useCallback(
    (value: boolean) => handleChange({ consentMarketing: value }),
    [handleChange],
  );
  const handleChangeVoucherCode = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      handleChange({ voucherCode: e.target.value.toUpperCase() }),
    [handleChange],
  );
  const handleChangeInterests = useCallback(
    (solution: SolutionOption) => {
      const solutionInterests = formState.solutionInterests.value;
      const newArray = [...solutionInterests];
      if (solution !== 'all' && solutionInterests.includes('all')) {
        newArray.splice(solutionInterests.indexOf('all'), 1);
      }
      if (solutionInterests.includes(solution)) {
        newArray.splice(solutionInterests.indexOf(solution), 1);
      } else {
        newArray.push(solution);
      }
      handleChange({ solutionInterests: newArray });
    },
    [formState.solutionInterests.value, handleChange],
  );

  // Dev-tools
  useEffect(() => {
    const handleKeydown = async (e: KeyboardEvent) => {
      if (config.isProduction) return;

      // press shift+ctrl+alt+\ to skip form with randomized data (dev purposes)
      if (e.ctrlKey && e.altKey && e.shiftKey && e.keyCode === 220) {
        const values: RequestPackageFormValues = {
          gender: 'other',
          firstName: 'Skip',
          lastName: 'Test',
          email: `remi+${Math.round(Math.random() * 100000)}@energiebespaarders.nl`,
          phone: '0666666666',
          appointmentDate: 'neutral',
          timeSlot: 7,
          consentTerms: false,
          consentMarketing: false,
          consentPartnerSharing: false,
          voucherCode: '',
          partnerOfficeId: '',
          selectedHouse: '',
          solutionInterests: [],
        };
        void (isAuthenticated.asCustomer
          ? handleRequestDutyPackageAsCustomer(values)
          : handleRequestDutyPackageAsLead(values));
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, [
    handleRequestDutyPackageAsCustomer,
    handleRequestDutyPackageAsLead,
    isAuthenticated.asCustomer,
  ]);

  const inputFontSize = useMemo(() => (isMobile ? 7 : 6), [isMobile]);

  return (
    <>
      <DelayedServiceWarning />
      {!selectedHouseId && !hideIntro && <p>Allereerst, om welke woning gaat het?</p>}
      <AddressInitForm
        linkColor={packageProps.color}
        selectedHouseId={selectedHouseId}
        setSelectedHouseId={setSelectedHouseId}
        isActive={!!dutyPackage}
        initialValues={initialAddress}
        validateAddress={validateAddress}
      />
      {errors.selectedHouse && !selectedHouseId && (
        <Small>
          <Red>{errors.selectedHouse}</Red>
        </Small>
      )}

      {!!selectedHouseId && ( // NEW SALES FLOW
        <>
          <LeadFilterForm
            themeColor={packageProps.color}
            selectedHouseId={selectedHouseId}
            leadBlockMessage={leadBlockMessage}
            blockRequest={(msg: string) => setLeadBlockMessage(msg)}
            unblockRequest={() => setLeadBlockMessage('')}
            solutionInterests={formState.solutionInterests.value}
            setSolutionInterests={(v: SolutionOption) => handleChange({ solutionInterests: v })}
            excludeFilters={[
              ...(packageProps.key === DutyPackageName.quotesOnly || !showSolutionChecklist
                ? (['interests'] as LeadFilterQuestion[])
                : []),
              ...(packageProps.key === 'drutenWijchen'
                ? (['isRenting', 'interests', 'isMonument'] as LeadFilterQuestion[])
                : []),
            ]}
          />
        </>
      )}

      <Accordion
        contentBgColor="transparent"
        contentPadding={0}
        isOpened={(!leadBlockMessage && !!selectedHouseId) || overrideCollapse}
        override
      >
        <Separator my={2} color="grayLight" />
        <form onSubmit={submitForm} style={{ position: 'relative' }}>
          {leadBlockMessage && <StyledOverlay />}

          <Flex flexWrap="wrap" mx="-3px">
            {showSolutionChecklist && (
              <Box width={1} px="3px">
                <SolutionChecklist
                  checkedSolutions={formState.solutionInterests.value}
                  omittedSolutions={[
                    Solution.AtticFloorInsulation,
                    Solution.CrawlspaceInsulation,
                    Solution.Sedum,
                  ]}
                  touched={formState.solutionInterests.touched}
                  error={formState.solutionInterests.error}
                  onChange={handleChangeInterests}
                  color={packageProps.color}
                  hideAllOption
                  fontSize={inputFontSize}
                  label="Voor welke oplossingen wil je offertes?"
                />
              </Box>
            )}

            {me?.__typename === 'Customer' && isAuthenticated.asCustomer && (
              <Box width={1} px="3px">
                <p>
                  U bent ingelogd als{' '}
                  <Medium>
                    {me.firstName} {me.lastName}
                  </Medium>
                  . Deze aanvraag wordt aan{' '}
                  <HybridLink to="/mijn-gegevens" passHref style={{ color: 'black' }}>
                    <u>uw account</u>
                  </HybridLink>{' '}
                  gekoppeld. Liever op een apart account?{' '}
                  <TextLink onClick={() => logout()} color="redDark">
                    <u>Log dan eerst uit</u>
                  </TextLink>
                  .
                </p>
              </Box>
            )}

            {isIncompleteCustomer && (
              <>
                <Box width={1} px="3px">
                  <GenderInput
                    id={'gender'}
                    bgColor={packageProps.color}
                    formState={formState.gender}
                    onChange={handleChangeGender}
                    fontSize={inputFontSize}
                  />
                </Box>
                <Box width={[1, 1, 1 / 2]} px="3px">
                  <Input
                    value={formState.firstName.value}
                    error={formState.firstName.error}
                    touched={formState.firstName.touched}
                    onChange={handleChangeFirstName}
                    type="text"
                    label="Voornaam"
                    labelColor="grayDarker"
                    fontSize={inputFontSize}
                    autoComplete="given-name"
                  />
                </Box>
                <Box width={[1, 1, 1 / 2]} px="3px">
                  <Input
                    value={formState.lastName.value}
                    error={formState.lastName.error}
                    touched={formState.lastName.touched}
                    onChange={handleChangeLastName}
                    type="text"
                    label="Achternaam"
                    labelColor="grayDarker"
                    fontSize={inputFontSize}
                    autoComplete="family-name"
                  />
                </Box>
                <Box width={[1, 1, 1 / 2]} px="3px">
                  <Input
                    value={formState.email.value}
                    error={formState.email.error}
                    touched={formState.email.touched}
                    onChange={handleChangeEmail}
                    type="email"
                    label="E-mailadres"
                    labelColor="grayDarker"
                    fontSize={inputFontSize}
                    autoComplete="email"
                    inputMode="email"
                  />
                </Box>
              </>
            )}

            {!meCustomer?.phone && (
              <Box width={!isIncompleteCustomer ? 1 : [1, 1, 1 / 2]} px="3px">
                <Input
                  value={formState.phone.value}
                  error={formState.phone.error}
                  touched={formState.phone.touched}
                  onChange={handleChangePhone}
                  type="tel"
                  label="Telefoonnummer"
                  labelColor="grayDarker"
                  fontSize={inputFontSize}
                  autoComplete="tel"
                  inputMode="tel"
                />
              </Box>
            )}
          </Flex>

          {!meCustomer && <Separator my={2} color="grayLight" />}

          {showAppointmentDropdown && (
            <Flex flexWrap="wrap" mx="-3px">
              <Box width={[1, 1, 1 / 2]} px="3px">
                <Select<string>
                  options={appointmentDateOptions}
                  value={appointmentDateOptions.find(
                    option => option.value === formState.appointmentDate.value,
                  )}
                  clearable={false}
                  error={formState.appointmentDate.error}
                  touched={formState.appointmentDate.touched}
                  label="Belafspraak datum"
                  labelColor="grayDarker"
                  onChange={handleChangeAppointmentDate}
                  fontSize={isMobile ? 7 : 6}
                />
              </Box>
              <Box width={[1, 1, 1 / 2]} px="3px">
                <Select<number>
                  options={timeSlotOptions}
                  value={timeSlotOptions.find(option => option.value === formState.timeSlot.value)}
                  clearable={false}
                  error={formState.timeSlot.error}
                  touched={formState.timeSlot.touched}
                  label="Belafspraak tijdvak"
                  labelColor="grayDarker"
                  onChange={handleChangeTimeSlot}
                  fontSize={isMobile ? 7 : 6}
                />
              </Box>
            </Flex>
          )}

          {packageProps.price !== 0 && (
            <VoucherInput
              labelColor="grayDarker"
              formState={formState.voucherCode}
              onChange={handleChangeVoucherCode}
              onInvalid={(msg: string) => setVoucherCodeInvalidError(msg)}
              onValid={(msg: string, value?: number, isPercentage?: boolean) => {
                setVoucherCodeValueMsg(msg);
                if (value) setDiscountAmount(isPercentage ? value * packageProps.price! : value);
              }}
              validityState={{
                validMessage: voucherCodeValueMsg,
                invalidMessage: voucherCodeInvalidError,
              }}
              inputFontSize={inputFontSize}
              dutyPackageProps={packageProps}
            />
          )}

          {(packageProps.key === DutyPackageName.veldsinkComplete ||
            packageProps.key === 'hypotheekVisieComplete') && (
            <Select<string>
              label={
                packageProps.key === DutyPackageName.veldsinkComplete
                  ? 'Veldsink Advieskantoor'
                  : packageProps.key === 'hypotheekVisieComplete'
                  ? 'Hypotheek Visie advieskantoor'
                  : 'Advieskantoor'
              }
              clearable={false}
              labelColor="grayDarker"
              loading={veldsinkOfficesLoading || hvOfficesLoading}
              options={officeOptions}
              value={officeOptions.find(option => option.value === formState.partnerOfficeId.value)}
              error={formState.partnerOfficeId.error}
              touched={formState.partnerOfficeId.touched}
              fontSize={isMobile ? 7 : 6}
              onChange={handleChangeVeldsinkOffice}
            />
          )}

          <Separator my={2} color="grayLight" />

          <TermsConsentCheckbox
            checked={formState.consentTerms.value}
            onToggle={handleChangeTermsConsent}
            error={formState.consentTerms.error}
          />
          <MarketingConsentCheckbox
            checked={formState.consentMarketing.value}
            onToggle={handleChangeMarketingConsent}
            error={formState.consentMarketing.error}
          />

          {errors.selectedHouse && !selectedHouseId && (
            <Small>
              <Red>{errors.selectedHouse}</Red>
            </Small>
          )}

          <Button
            bgColor={packageProps.color}
            type="submit"
            fluid
            fontSize={5}
            iconStart={errors.general || submitError ? Cancel : Check}
            label={
              submitText ||
              (packageProps.price === 0
                ? `${packageProps.fullName} kosteloos aanvragen`
                : `${packageProps.fullName} aanvragen voor ${
                    !isUndefined(packageProps.price) ? 'slechts ' : ''
                  }€${delimit(packageProps.price!, hasDecimals ? 2 : 0)}`)
            }
            loading={loading}
            error={submissionAttempted ? errors.general || submitError : undefined}
            disabled={!!leadBlockMessage}
            onClick={triggerValidation}
          />
        </form>
      </Accordion>
    </>
  );
};

export default RequestPackageForm;
